:root {
    --page-width: 80%;
    --font-size: 16px;
    --text-color: #2c3e50;
    --sidebar-bg-color: #2c3e50;
    --header-bg-color: #2c3e50;
    --content-bg-color: #f9f9f9;
    --btn-bg-color: #007bff;
    --btn-txt-color: #ffffff;
    --btn-hover-bg-color: #0056b3;
    --btn-hover-txt-color: #ffffff;
}


html {
    font-size: 100%; /* 16px by default in most browsers */
}

.App {
    display: flex;
    flex-direction: row; /* Keep sidebar and content side by side */
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Prevents overflow for a clean layout */
}


.Sidebar {
    width: 15vw; /* Slightly smaller for more main content space */
    height: 100vh; /* Full viewport height */
    overflow-y: auto; /* Allow scrolling for overflow content */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns links and elements to the start */
    background-color: var(--sidebar-bg-color);
    color: #fff; /* Text color */
    position: fixed;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Adds a soft shadow */
    z-index: 1000;
}

.Sidebar-header {
    display: flex;
    align-items: flex-start; /* Vertically centers content */
    white-space: nowrap; /* Prevents text from wrapping */
    text-align: left;
    margin-left: 1em;
    margin-right: -5em;
    padding: 0px;
}

.Sidebar-logo {
    width: 4em; /* Adjust as needed */
    height: 4em; /* Match the width for a square */
    margin-top: -0.6em;
    margin-left: -1em;
    margin-right: 0.3em; /* Adds space between the logo and text */
}

.Sidebar-title {
    position: relative; /* Position it relative to .Sidebar-header */
    left: 0; /* Start at the left edge of the sidebar */
    white-space: nowrap; /* Prevent text wrapping */
    margin-top: 0.3em;
    font-size: 1.8em;
    font-weight: bold;
    color: #FFFFFF;
    z-index: 1100; /* Ensure it appears above other content */
}

.Sidebar nav {
    display: flex; /* Ensure each link is treated as a block element */
    flex-direction: column; /* Arrange items in a column */
    align-items: flex-start; /* Align items to the left */
    flex-grow: 1; /* Allow this section to grow to fill remaining space */
    overflow-y: auto; /* Add scrolling for overflow content */
    margin-top: 0px;
    gap: 0px;
    padding: 0px;
}


.Sidebar-link {
    display: block; /* Ensure each link is treated as a block element */
    white-space: normal; /* Allow the text to wrap onto the next line */
    overflow-wrap: break-word; /* Break long words if necessary */
    word-wrap: break-word; /* Ensure compatibility with older browsers */
    padding: 0.5em; /* 12 px */
    color: white;
    text-decoration: none;
    margin-left: 1.2em;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

.Sidebar-link:hover {
    background-color: #3b5169; /* Slightly lighter than sidebar */
}

.Sidebar-animation {
    margin-bottom: 0.3em;
    width: 100%;
    height: 6em; /* Fixed height */
    background-color: var(--sidebar-bg-color);
    display: column; /* Ensure alignment */
    justify-content: center; /* Center the animation horizontally */
    align-items: center; /* Center the animation vertically */
    flex-shrink: 0; /* Prevent the height from being reduced */
    overflow: hidden; /* Hide overflow content */
}

/* Laptop Design */
.laptop {
    width: 6.5em;
    height: 4.5em; /* Increased height for better proportions */
    background: #333; /* Laptop body */
    border-radius: 10px 10px 0 0;
    position: relative;
    margin: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Laptop Screen (Centered Inside Laptop) */
.laptop-screen {
    width: 90%;
    height: 80%; /* Reduce screen height for proper framing */
    background: #1e1e1e; /* Screen background */
    margin: auto; /* Centered vertically */
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    overflow: hidden;
}

/* Simulated Keyboard */
.laptop-keyboard {
    width: 9em; /* Slightly wider than the laptop body */
    height: 0.7em; /* Thickness of the keyboard */
    background: #444; /* Dark gray to simulate keyboard color */
    margin: 3px auto 0; /* positive small margin for gap between screen and the keyboard */
    border-radius: 0 0 0.5em 0.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Typing Animation Container */
.typing-container {
    width: 100%;
    font-family: monospace;
    text-align: center; /* Center text horizontally */
}

/* Typing Lines */
.typing-line1, .typing-line2, .typing-line3 {
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.6em;
    border-right: 2px solid #00ff00; /* Blinking cursor */
    display: block;
    margin: 5px 0; /* Spacing between lines */
}

/* Line Animations */
.typing-line1 {
    width: 0;
    margin-top: 0;
    margin-bottom: 0;
    color: #00ff00; /* Retro green text */
    animation: typing 3s steps(20, end) 0s forwards, blink 0.7s infinite;
}

.typing-line2 {
    width: 0;
    margin-top: 0;
    margin-bottom: 0;
    background: linear-gradient(90deg, #ff0000, #ff9900, #ffff00, #00ff00);
    -webkit-background-clip: text;
    color: transparent;
    animation: typing 3s steps(20, end) 3s forwards, blink 0.7s infinite;
}

.typing-line3 {
    width: 0;
    margin-top: 0;
    margin-bottom: 0;
    background: linear-gradient(90deg, #ff0000, #ff9900, #ffff00, #00ff00);
    -webkit-background-clip: text;
    color: transparent;
    animation: typing 3s steps(20, end) 6s forwards, blink 0.7s infinite;
}

/* Typing Keyframes */
@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
}

/* Blinking Cursor */
@keyframes blink {
    50% { border-color: transparent; }
}


.HorizontalRule {
    margin: 20px 0; /* Adds spacing around the line */
    border-top: 1px solid rgba(255, 255, 255, 0.5); /* White line with 50% opacity */
    width: 100%; /* Ensures it spans the width of Sidebar */
}

/* Main Content Area */
.MainContent {
    background-color: var(--content-bg-color);
    margin-left: 15vw; /* Shift MainContent right by Sidebar width */
    padding: 0px;
    flex: 1; /* Allows MainContent to take up the rest of the screen */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Keep padding within bounds */
}

body {
  font-size: var(--font-size);
  color: var(--text-color);
  background-color: var(--content-bg-color);
}

.App-header {
    background-color: #282c34; /* Dark header background */
    min-height: 20vh; /* Reduced height to give more space to content */
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin); /* Responsive text size */
    color: white;
    text-align: center;
    box-sizing: border-box; /* Ensures padding is within height */
    margin-top: 0px;
}

.App-header h2 {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.5em; /* Adjust as desired */
    width: 100%;
}

.App-header h4 {
    margin-top: 0px;
    margin-bottom: 1em;
    font-size: 1em; /* Adjust as desired */
    line-height: 1.1; /* Reduces space between lines */
}

.App-header p {
    margin-top: 0px;
    margin-bottom: 2em;
    font-size: 0.8em; /* Adjust the font size of the third line */
    line-height: 1.1;
    width: 100%;
}

.highlighted-text {
    font-weight: bold;
    background: linear-gradient(90deg, #ff0000, #ff9900, #ffff00, #00ff00, #00ccff, #9933ff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 1.2em;
}


.Page-header {
    background-color: var(--header-bg-color);
    color: #fff;
    padding: 0.2em;
    text-align: center;
    font-size: 1.0em;
    font-weight: bold;
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Keep padding within bounds */
    min-height: 2.0em;
    flex-shrink: 0; /* Prevent the header from shrinking */
}


/* Container for the tabs within MainContent */
.Page-tabs {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 30px;
}

.Page-tabs-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 0px;
    gap: 5px;
    padding-bottom: 0px;
}

.Page-tabs-logo {
    width: 4em;
    height: 4em;
    margin-right: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.Page-tabs-title {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1.8em;
}

.Page-tab {
    color: white;
    text-decoration: none;
    font-size: 0.9em;
    cursor: pointer;
    margin-right: 10px;
}

.Page-tab:hover {
    text-decoration: underline;
}

.Page-maincontent {
    background-color: var(--content-bg-color);
    padding: 40px;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0px;
    margin-left: 2em;
    margin-right: 0em;
    width: var(--page-width);
    box-sizing: border-box;
    flex-grow: 1; /* Allow this section to grow and take up remaining space */
}


.Page-maincontent h1{
    margin-top: -0.5em;
    margin-bottom: 0px;
}


.textarea-container {
    width: 100%;
    display: flex;
    gap: 40px;
    font-size: 1em;
}


.textarea h3 {
    margin-left: 40px;
}

.textarea p {
    margin-top: 0px;
    margin-bottom: 25px;
    margin-left: 80px;
}


.textarea h4 {
    margin-top: 40px;
    margin-bottom: 25px;
    font-size: 1.1em;
}

.textarea-item.A {
    flex: 1; /* 1/3 of the screen */
}


.textarea-item.B {
    flex: 2; /* 2/3 of the screen */
}

.textarea-item.C {
    flex: 3;
}

.textarea-item.D {
    flex: 1.5;
}
.textarea-item.word-limit {
    width: 80px;
}


/* Default sidebar hidden on small screens */
.Sidebar {
    transition: transform 0.3s ease;
}

/* Hamburger icon styling */
.hamburger {
    display: none; /* Hide by default on larger screens */
    font-size: 2.5em; /* Adjust size as needed */
    color: white;
    background: none;
    border: none;
    position: fixed; /* Fix it to the screen */
    top: 0.4em; /* Position near the top: 15px */
    left: 5px; /* Position near the left: 15px */
    z-index: 1000; /* High z-index to make sure it stays on top */
    cursor: pointer;
}

@media (max-width: 768px) {
    .App-header, .App-main, .Page-header, .Page-main {
        padding: 10px;
        font-size: 1em;
    }

    .TopBar {
        display: none; /* Hide TopBar on larger screens */
    }

    .Sidebar {
        transform: translateX(-150%); /* Hide sidebar off-screen by default */
    }

    /* Show the hamburger icon on small screens */
    .hamburger {
        display: block;
    }

    /* Display sidebar when open */
    .Sidebar.open {
        transform: translateX(0); /* Slide in from the left */
        position: fixed;
        width: 12em; /* Adjust width for small screens */
        max-width: 240px;
        height: 100vh;
        z-index: 999; /* Ensure it layers above main content but below the hamburger */
    }

    /* Style for Sidebar-header when the sidebar is open */
    .Sidebar.open .Sidebar-header {
        margin-top: 4em;
    }

    .Sidebar.open .Sidebar-logo {
        display: none;
    }

    .Sidebar.open .Sidebar-title {
        display: none
    }


    /* Hide Sidebar-title and Sidebar-logo when the sidebar is hidden (hamburger menu visible) */
    .Sidebar:not(.open) .Sidebar-title,
    .Sidebar:not(.open) .Sidebar-logo {
        display: none;
    }

    .Sidebar-link {
        padding: 0.5em;
    }

    /* Adjust main content when sidebar is open */
    .MainContent {
        margin-left: 0;
    }


    .textarea-container {
        flex-direction: column; /* Stack items vertically on smaller screens */
    }

    .video-container {
        width: 80%; /* Reduces video size on smaller screens */
    }
}

.spinner.brainstorming {
    position: relative;
    width: 160px;
    height: 20px;
}

.idea {
    width: 15px;
    height: 15px;
    background-color: #3498db;
    border-radius: 50%;
    position: absolute;
    animation: floating 1.5s ease-in-out infinite;
}

/* Different colors for each dot */
.idea1 { top: 25%; left: 15%; background-color: #ff0000; animation-delay: 0s; } /* Red */
.idea2 { top: 85%; left: 30%; background-color: #ff9900; animation-delay: 0.3s; } /* Yellow */
.idea3 { top: 25%; left: 45%; background-color: #ffff00; animation-delay: 0.6s; } /* Green */
.idea4 { top: 85%; left: 60%; background-color: #00ff00; animation-delay: 0.9s; } /* Blue */
.idea5 { top: 25%; left: 75%; background-color: #00ccff; animation-delay: 1.2s; } /* Blue */
.idea6 { top: 85%; left: 90%; background-color: #9933ff; animation-delay: 1.5s; } /* Blue */


@keyframes floating {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-15px); } /* Increased bounce height */
}


.writing-spinner {
    position: relative;
    width: 160px;
    height: 40px;
    overflow: hidden;
}

.pen {
    font-size: 1.6em; /* Adjust as needed */
    position: absolute;
    top: 10px; /* Starting vertical position */
    animation: movePen 2s linear infinite, penBounce 0.5s ease-in-out infinite;
}

.trace {
    position: absolute;
    width: 0;
    height: 2px;
    background: rgba(0, 0, 0, 0.2); /* Simulates a light gray line */
    top: 30px; /* Vertical position of the trace line */
    left: 0;
    animation: drawLine 2s linear infinite;
}

@keyframes movePen {
    0% { left: 0; }
    50% { left: 160px; }
    100% { left: 0; }
}

@keyframes penBounce {
    0%, 100% { top: 10px; }
    50% { top: 5px; } /* Simulates slight vertical movement */
}

@keyframes drawLine {
    0% { width: 0; }
    50% { width: 160px; }
    100% { width: 0; }
}

.simpleSpinner {
    width: 25px;
    height: 25px;
    margin-left: 20px;
    border: 4px solid rgba(0, 0, 0, 0.2); /* Light gray border */
    border-top: 4px solid #000; /* Black top border for contrast */
    border-radius: 50%; /* Makes it a circle */
    animation: simpleSpinnerSpin 1s linear infinite;
}

@keyframes simpleSpinnerSpin {
  0% {
    transform: rotate(0deg); /* Starting rotation */
  }
  100% {
    transform: rotate(360deg); /* Full rotation */
  }
}

.accordion {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensure padding and border are within width */
}

.accordion-header {
    padding: 0.3em;
    font-weight: bold;
    cursor: pointer;
    border-bottom: none; /* 1px solid #ccc; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordion-header:hover {
    background-color: var(--btn-hover-bg-color); /* Subtle background color change on hover */
    color: var(--btn-hover-txt-color);
}

.accordion-content {
    margin-left: 0.3em;
    margin-bottom: 0.3em;
    width: 98%;
    border-top: none;
}

.options-box {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: var(--content-bg-color);
  margin-top: 0px;
  margin-bottom: 10px;
  min-height: 125px;
}

.options-box h3 {
  margin-top: 0; /* Remove or reduce the top margin */
}

.options-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: -15px;
}

.option-group {
  flex: 1;
  margin-right: 20px; /* Add some space between columns */
}

.option-group:last-child {
  margin-right: 0; /* Remove space for the last column */
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

/* Spinner Container */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust as needed */
  position: relative;
}

/* Laptop Emoji */
.laptop-emoji {
  font-size: 60px; /* Adjust size as needed */
  position: relative;
  z-index: 1; /* Brings the laptop in front */
}

/* Pen Emoji */
.pen-emoji {
  font-size: 1.6em; /* Adjust size as needed */
  position: absolute;
  top: 5px; /* Adjust to position pen over the laptop */
  left: 50%;
  transform: translateX(-50%);
  animation: flip 3s linear infinite;
  z-index: 2; /* Brings the pen in front of the laptop */
}

/* Flip Animation */
@keyframes flip {
  0% {
    transform: translateX(-50%) rotate(0deg) translateY(30px);
  }
  50% {
    transform: translateX(-50%) rotate(180deg) translateY(0px); /* Pen flips over the laptop */
  }
  100% {
    transform: translateX(-50%) rotate(360deg) translateY(30px);
  }
}

.product-catalog {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 0px;
  width: 470px;
  background-color: var(--content-bg-color);
  color: var(--text-color);
}

.catalog-box {
  border: 0px solid #ddd;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.catalog-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.coupon-section {
  margin-top: 20px;
}

h2, h3 {
  margin-bottom: 10px;
}

.contact-us {
  max-width: 1000px;
  margin-top: 0px;
  padding: 20px;
  border: 0px solid #ddd;
  border-radius: 8px;
  background-color: var(--content-bg-color);
}

.contact-methods {
  display: flex;
  justify-content: center;
  gap: 60px; /* Adds comfortable space between the buttons */
}

.contact-methods button {
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--text-color);
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for elevation */
  background-color: var(--btn-bg-color); /* Subtle background color change on hover */
  color: var(--btn-txt-color);
  transition: background-color 0.3s ease;
}

.contact-methods button:hover {
  background-color: var(--btn-hover-bg-color); /* Subtle background color change on hover */
  color: var(--btn-hover-txt-color);
  transform: scale(1.2); /* Slight enlargement effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Slightly deeper shadow on hover */
}

.contact-form {
  margin-top: 20px;
}

.contact-form textarea,
.contact-form input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 0.8em;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.success-message {
  color: green;
  text-align: center;
}

.error-message {
  color: red;
  text-align: center;
}

/* Broad action buttons */
.btn-broad-action {
    font-size: 1.1em; /* Slightly larger font size */
    font-weight: bold; /* Bold text */
    padding: 0.5em 1em; /* Comfortable padding */
    border-radius: 0.5em; /* Rounded corners */
    margin-bottom: 2em;
    margin-top: 2em;
    background-color: var(--btn-bg-color);
    color: var(--btn-txt-color);
    box-shadow: 0 0.2em 0.5em rgba(0, 0, 0, 0.2); /* Subtle shadow for elevation */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s; /* Smooth hover effects */
}

.btn-broad-action:hover {
    background-color: var(--btn-hover-bg-color); /* Subtle background color change on hover */
    color: var(--btn-hover-txt-color);
    transform: scale(1.05); /* Slight enlargement effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Slightly deeper shadow on hover */
}


/* Record management buttons */
.btn-record-action {
    font-size: 0.9em; /* Default font size */
    font-weight: normal; /* Normal text weight */
    background-color: var(--btn-bg-color);
    color: var(--btn-txt-color);
    padding: 2px 12px; /* Comfortable padding */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s, transform 0.2s; /* Smooth hover effects */
}

.btn-record-action:hover {
    background-color: var(--btn-hover-bg-color); /* Subtle background color change on hover */
    color: var(--btn-hover-txt-color);
    transform: scale(1.05); /* Slight enlargement effect */
}

.btn-record-managing {
    font-size: 0.8em; /* Default font size */
    font-weight: normal; /* Normal text weight */
    background-color: var(--btn-bg-color);
    color: var(--btn-txt-color);
    padding: 1px 12px; /* Comfortable padding */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s, transform 0.2s; /* Smooth hover effects */
}

.btn-record-managing:hover {
    background-color: var(--btn-hover-bg-color); /* Subtle background color change on hover */
    color: var(--btn-hover-txt-color);
    transform: scale(1.2); /* Slight enlargement effect */
}

/* ManageWritingAccount.css */

.manage-writing-account {
  padding: 20px;
}

.actions {
  margin-bottom: 10px;
}

.actions button {
  margin-right: 20px;
}

.essay-list .folder {
  margin-bottom: 20px;
}

.essay-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 80%;
  max-width: 850px;
  min-width: 470px;
  max-height: 90vh; /* Ensure the modal does not exceed the viewport height */
  overflow-y: auto; /* Enable vertical scrolling within the modal */
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

.modal-content label {
  display: block;
  margin-bottom: 10px;
}

.modal-content .actions {
  margin-top: 20px;
  text-align: right;
}


.btn-toggle-section {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8em;
}

.btn-toggle-section:hover {
  background-color: #0056b3;
}

.actions {
  display: flex;
  justify-content: space-between;
}

p {
  margin: 5px 0;
}


/* Container simulating the video player */
.video-placeholder-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    min-width: 478px;
    aspect-ratio: 16 / 9;
    background: #000 ;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Text overlay */
.video-text-overlay {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: clamp(1rem, 1.5vw, 2rem);
    line-height: 1.5;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

/* Play button container */
.video-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

/* Play button circle */
.circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Play button arrow */
.arrow {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 20px solid #000;
    margin-left: 5px;
}


.edit-account-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 80%;
    max-width: 600px; /* Set maximum width */
    margin: 0 auto; /* Center horizontally */
    margin-top: 20px;
    padding: 20px;
    background-color: var(--content-bg-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-section {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border: 1px solid var(--text-color);
    border-radius: 5px;
    background-color: var(--content-bg-color);
}

.edit-section-header {
    display: flex;
    justify-content: space-between; /* Push button to the right */
    align-items: center; /* Align title and button vertically */
    width: 100%;
}

/* Ensure proper alignment for the checkbox label */
.left-aligned {
    display: flex;
    align-items: center; /* Align checkbox and label text vertically */
    gap: 10px;
    margin: 5px 0;
    width: 100%; /* Ensure it spans full width of its container */
}

/* Explicitly set label to prevent interference */
.edit-section label {
    display: flex; /* Ensure checkbox and text are in a row */
    align-items: center; /* Vertically align checkbox and label text */
    justify-content: flex-start; /* Align everything to the left */
    gap: 10px; /* Control the space between checkbox and text */
    width: auto; /* Prevent unintended flex-grow */
}

/* Reset checkbox-specific behavior */
.edit-section input[type="checkbox"] {
    margin: 0; /* Remove browser-added margins */
    flex-shrink: 0; /* Prevent checkbox from shrinking */
    width: auto; /* Ensure checkbox doesn't expand */
}

/* Reset checkbox-specific behavior */
.edit-section input[type="radio"] {
    margin: 0; /* Remove browser-added margins */
    flex-shrink: 0; /* Prevent checkbox from shrinking */
    width: auto; /* Ensure checkbox doesn't expand */
}

.edit-section h3 {
    margin: 0;
    font-size: 1.2em;
    color: var(--text-color);
}

.edit-section p {
    margin: 0;
    font-size: 0.9em;
    color: var(--text-color);
}

.edit-section input {
    width: 80%;
    padding: 8px;
    font-size: var(--font-size);
    border: 1px solid var(--text-color);
    border-radius: 4px;
    color: var(--text-color);
    background-color: var(--content-bg-color);
}

.edit-section button {
    align-self: flex-end;
    padding: 8px 12px;
    font-size: calc(var(--font-size) - 2px);
    color: var(--btn-txt-color);
    background-color: var(--btn-bg-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.edit-section button:hover {
    background-color: var(--btn-hover-bg-color);
    color: var(--btn-hover-txt-color);
}

.edit-section success-message {
    color: green;
    font-size: var(--font-size);
}

.edit-section error-message {
    color: red;
    font-size: var(--font-size);
}

/* New container to handle responsive layout */
.responsive-row {
    display: flex;
    gap: 10px; /* Space between select and input */
    width: 100%; /* Take full available width */
}

/* Responsive styling for the select dropdown */
.responsive-select {
    flex: 1; /* Allocate 2 parts of space to the select */
    max-width: 70%; /* Prevent select from dominating small screens */
    font-size: 0.9em;
    padding: 5px;
    border: 1px solid var(--text-color);
    border-radius: 4px;
}

/* Responsive styling for the input field */
.responsive-input {
    flex: 1; /* Allocate 1 part of space to the input */
    font-size: 0.9em;
    padding: 5px;
    border: 1px solid var(--text-color);
    border-radius: 4px;
}

.NoticeBoard {
    background-color: #fff;
    border: 2px solid #4caf50; /* Green border for success */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    max-width: 600px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}


/* NoticeBoard heading */
.NoticeBoard h1 {
    font-size: 1.8rem;
    color: #4caf50; /* Match the success theme */
    margin-bottom: 10px;
}

/* NoticeBoard paragraph */
.NoticeBoard p {
    font-size: 1rem;
    color: #333;
    margin: 10px 0;
}

/* NoticeBoard links */
.NoticeBoard a {
    color: #007bff; /* Blue links */
    text-decoration: none;
}

.NoticeBoard a:hover {
    text-decoration: underline;
}

.progress-circle-container {
    position: relative;
    display: inline-block;
    width: 100;
    height: 100;
}

.progress-circle-container svg {
    transform: rotate(-90deg); /* Rotate to start progress at the top */
}

.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1em;
    font-weight: bold;
    color: #4caf50; /* Match the progress color */
}
